a {
  text-decoration: underline;
  color: blue;
}

@media (min-width: 576px) {
  .footer {
    .desktop-view {
      display: flex !important;
      width: 100%;
      align-items: center !important;
    }

    .copyright {
      margin-top: 0px !important;
    }
  }
}

.footer {
  width: 100%;
  background-color: #111827; 
  align-items: center;
  display: flex;
  flex-direction: column;

  .custom-flex-footer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .desktop-view {
    display: contents;
  }

  .copyright {
    width: 100%;
    height: 23px;
    background-color: #02050d;
    text-align: center;
    margin-top: 2rem;
    .message {
      font-family: "Helvetica Neue-Regular", Helvetica;
      font-weight: 400;
      color: #d4d4d4;
      font-size: 12px;
      letter-spacing: 0;
      line-height: normal;
      white-space: nowrap;
    }
  }

  .text-wrapper {
    font-family: "Helvetica Neue-Regular", Helvetica;
    font-weight: 400;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: normal;
    text-decoration: underline;
    white-space: nowrap;
  }

  a {
    color: #ffffff;
  }

  .rectangle {
    width: 2px;
    height: 20px;
    background-color: #ffffff;
    opacity: 0.3;
  }
  
  .zynga-image {
    height: 40px;
    width: 40px;
    margin: 1.5rem;
  }
}